import { useEffect, useRef, useState } from "react";
import { ReactFlowProvider } from "reactflow";
import { Intent } from "@blueprintjs/core";
import { BlueprintIcon } from "@ui/BlueprintIcon";
import classNames from "classnames";
import { observer } from "mobx-react";
import { isAlive } from "mobx-state-tree";

import HorizontalLayoutIcon from "@assets/icons/horizontal-layout.svg?react";
import { Button } from "@components/Button";
import AttachmentsCard from "@components/CatalogItemPreview/Components/AttachmentsCard";
import OverviewCard from "@components/CatalogItemPreview/Components/OverviewCard";
import RelationsCard from "@components/CatalogItemPreview/Components/RelationsCard";
import SourcingCard from "@components/CatalogItemPreview/Components/SourcingCard";
import SpecificationsCard from "@components/CatalogItemPreview/Components/SpecificationsCard";
import VersionsCard from "@components/CatalogItemPreview/Components/VersionsCard";
import { DragHandle } from "@components/DragHandle";
import appStore from "@store/AppStore";

import styles from "./CatalogItemPreview.module.scss";

type Props = {
  catalogItemPreviewId?: string;
  catalogItemReferencePreviewId?: string;
  resetCatalogItemPreviewId?: () => void;
  resetCatalogItemReferencePreviewId?: () => void;
  isVertical?: boolean;
  disabled?: boolean;
  toggleVertical?: () => void;
};

const CatalogItemPreview = (props: Props) => {
  const {
    catalogItemPreviewId = "",
    catalogItemReferencePreviewId = "",
    resetCatalogItemPreviewId,
    resetCatalogItemReferencePreviewId,
    isVertical,
    toggleVertical,
    disabled,
  } = props;
  const catalogItemReference = appStore.orgModel.catalogItems.getCatalogItemReference(catalogItemReferencePreviewId);
  const catalogItem = catalogItemReferencePreviewId
    ? catalogItemReference?.referencedCatalogItem
    : appStore.orgModel.catalogItems.getCatalogItem(catalogItemPreviewId);
  const [activeVersion, setActiveVersion] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (catalogItem?.latestVersion) {
      setActiveVersion(catalogItem.latestVersion.id);
    }
  }, [catalogItem, catalogItem?.versions.length]);

  if (!catalogItem || !isAlive(catalogItem)) {
    return null;
  }

  return (
    <div className={classNames(styles.catalogItemPreview, { [styles.catalogItemPreviewVertical]: isVertical })}>
      <DragHandle className={styles.catalogItemPreviewDragHandle} vertical={isVertical} />
      <div className={styles.catalogItemPreviewContent}>
        <div className={styles.catalogItemPreviewHeader}>
          <div className={styles.catalogItemPreviewHeaderLeft}>
            <BlueprintIcon icon="layers" />
            <span>{catalogItem.name}</span>
            {!disabled && (
              <Button
                className={styles.catalogItemPreviewHeaderButton}
                intent={Intent.PRIMARY}
                text="Update item design"
                onClick={() => appStore.ui.setShowPdmNewVersionDialog(catalogItem.id)}
                e2eIdentifiers="new-version"
              />
            )}
          </div>
          <div className={styles.catalogItemPreviewHeaderRight}>
            <Button
              icon={isVertical ? <HorizontalLayoutIcon /> : "column-layout"}
              e2eIdentifiers="column-preview-catalog-item"
              minimal
              onClick={toggleVertical}
            />
            <Button
              icon="cross"
              e2eIdentifiers="close-catalog-item"
              minimal
              onClick={() => {
                resetCatalogItemPreviewId?.();
                resetCatalogItemReferencePreviewId?.();
              }}
            />
          </div>
        </div>
        <div className={styles.catalogItemPreviewBody} ref={containerRef}>
          <OverviewCard disabled={disabled} isVertical={isVertical} catalogItem={catalogItem} />
          <SpecificationsCard
            disabled={disabled}
            isVertical={isVertical}
            onVersionChange={setActiveVersion}
            activeVersion={activeVersion}
            catalogItem={catalogItem}
          />
          <SourcingCard disabled={disabled} isVertical={isVertical} activeVersion={activeVersion} />
          <AttachmentsCard disabled={disabled} isVertical={isVertical} onVersionChange={setActiveVersion} catalogItem={catalogItem} />
          <VersionsCard isVertical={isVertical} onVersionClick={setActiveVersion} activeVersion={activeVersion} catalogItem={catalogItem} />
          <ReactFlowProvider>
            <RelationsCard containerRef={containerRef} activeVersion={activeVersion} isVertical={isVertical} catalogItem={catalogItem} />
          </ReactFlowProvider>
        </div>
      </div>
    </div>
  );
};

export default observer(CatalogItemPreview);
