import { Text, TextVariant } from "@ui/Text";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { FormGroup } from "@components/FormGroup";
import { InputGroup } from "@components/InputGroup";
import MultiSelectTag from "@components/MultiSelectTag";
import {
  createVerificationMethodOption,
  getLevelCellText,
  mapLevelTextToNumber,
  requirementLevelTextOptions,
} from "@components/Requirements/Page/RequirementPageUtils";
import { VerificationLogic } from "@components/Requirements/VerificationLogic";
import { RichTextEditor } from "@components/RichTextEditor";
import { SegmentedControl } from "@components/SegmentedControl";
import { Dropdown, IComplexSelectOption } from "@components/Shared/Dropdown";
import { FunctionalType, RequirementVerificationMethod, SuccessCriteriaType } from "@rollup-api/models";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { enhanceStringOptionWithCase } from "@utilities/SelectionUtils";

import { LinkedBlockButton } from "../LinkedBlockButton";
import { LinkedPropertyInput } from "../LinkedPropertyInput";

import styles from "./RequirementDetailsPanel.module.scss";

interface IRequirementDetailsPanelProps {
  reqBlock: IRequirementBlock;
}

const RequirementDetailsPanel = (props: IRequirementDetailsPanelProps) => {
  const { reqBlock } = props;

  return (
    <div className={styles.requirementDetailsPanel}>
      <div className={styles.header}>
        <div className={styles.headerIdColumn}>
          <Text variant={TextVariant.Caption} disableAlignmentWrapper>
            {reqBlock.computedVisibleId}
          </Text>
          <RichTextEditor className={styles.title} content={reqBlock.label} placeholder="Untitled Requirement" isEditable={false} noWrap />
        </div>
        <Button icon="cross" onClick={appStore.env.sidePanel.hideReqSidePanel} e2eIdentifiers="close-req-details" minimal />
      </div>
      <div className={styles.body}>
        <FormGroup className={styles.formGroup} label="ID">
          <InputGroup
            className={styles.inputGroup}
            value={reqBlock.computedVisibleId}
            onChange={e => reqBlock.setVisibleId(e.target.value)}
            e2eIdentifiers="requirement-id-input"
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Level">
          <Dropdown
            className={styles.dropdown}
            popoverProps={{ popoverClassName: styles.requirementDetailsPanelDropdownMenu }}
            selected={getLevelCellText(reqBlock.level)}
            options={requirementLevelTextOptions}
            onSelect={value => reqBlock.setLevel(mapLevelTextToNumber(value))}
            e2eIdentifiers="open-level-dropdown"
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Title">
          <RichTextEditor content={reqBlock.label} placeholder="Untitled Requirement" onCommit={reqBlock.setLabel} />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Type">
          <Dropdown<IComplexSelectOption<FunctionalType>>
            className={styles.dropdown}
            popupMenuClassName="test_123"
            popoverProps={{ popoverClassName: styles.requirementDetailsPanelDropdownMenu }}
            selected={enhanceStringOptionWithCase(reqBlock.functionalType)}
            options={Object.values(FunctionalType).map(enhanceStringOptionWithCase)}
            onSelect={opt => reqBlock.setFunctionalType(opt.value)}
            e2eIdentifiers="open-type-dropdown"
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Description">
          <RichTextEditor content={reqBlock.description} onCommit={reqBlock.setDescription} />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Rationale">
          <RichTextEditor content={reqBlock.rationale} onCommit={reqBlock.setRationale} />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Linked Block">
          <LinkedBlockButton className={styles.linkedBlock} noneClassName={styles.noneBlockLinked} reqBlock={reqBlock} outlined />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Linked Property">
          <LinkedPropertyInput className={styles.inputGroup} requirementBlock={reqBlock} />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Verification Method">
          <MultiSelectTag
            className={styles.multiSelectTag}
            containerClassName={classNames(styles.multiSelectTagContainer, {
              [styles.multiSelectTagContainerEmpty]: !reqBlock.verificationMethods.length,
            })}
            items={Object.values(RequirementVerificationMethod).map(createVerificationMethodOption)}
            selectedItems={reqBlock.verificationMethods.map(createVerificationMethodOption)}
            onItemSelect={item => reqBlock.toggleVerificationMethod(item.id as RequirementVerificationMethod)}
            onItemRemove={item => reqBlock.removeVerificationMethod(item.id as RequirementVerificationMethod)}
            e2eIdentifiers="open-verification-method-dropdown"
            compact
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Success Criteria">
          <SegmentedControl
            value={reqBlock.successCriteria}
            options={Object.values(SuccessCriteriaType).map(enhanceStringOptionWithCase)}
            onValueChange={value => reqBlock.setSuccessCriteria(value as SuccessCriteriaType)}
            inline
            small
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Verification">
          <VerificationLogic
            requirementBlock={reqBlock}
            className={styles.verification}
            automaticClassName={styles.verificationAutomatic}
            valueInputClassName={styles.verificationValue}
            unitInputClassName={styles.verificationUnit}
            outlined
          />
        </FormGroup>
        <FormGroup className={styles.formGroup} label="Notes">
          <RichTextEditor content={reqBlock.note} onCommit={reqBlock.setNote} />
        </FormGroup>
      </div>
    </div>
  );
};

export default observer(RequirementDetailsPanel);
