import { EFileFormat } from "@components/FileDropZone";
import { ONE_MB_IN_BYTES } from "@utilities/FileUpload";

export const FILE_IMPORT_LIMIT = 750 * ONE_MB_IN_BYTES;

export const FILE_FORMATS = [
  EFileFormat.PRT,
  EFileFormat.SLDASM,
  EFileFormat.SLDPRT,
  EFileFormat.STP,
  EFileFormat.STEP,
  EFileFormat.PRT,
  EFileFormat.IGS,
  EFileFormat.ZIP,
];
