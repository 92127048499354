import { useCallback, useEffect, useState } from "react";
import { HTMLTable } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import csv from "papaparse";

import { Button } from "@components/Button";
import { showApiErrorToast } from "@components/UiLayers/toaster";
import { SelectItem } from "src/ui/Select/Item/SelectItem";
import { Text } from "src/ui/Text";

import { CELL_WIDTH } from "../../constants";

import "./HeadersPanel.scss";

type Props<T extends string> = {
  file: File | null;
  columnsMap: Record<T, string>;
  columnNameMap: Record<T, string>;
  onChange(columnsMap: Record<T, string>): void;
};

const handleParsingError = (err: Error) => {
  showApiErrorToast("Error parsing CSV file", err);
};

const HeadersPanel = <CsvColumn extends string>(props: Props<CsvColumn>) => {
  const [headers, setHeaders] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const [values, setValues] = useState<Record<string, string>[]>([]);
  const [hasParsedFile, setHasParsedFile] = useState(false);
  const { file, columnsMap, columnNameMap, onChange } = props;
  const availableHeaders = Object.keys(columnsMap) as CsvColumn[];

  const automaticallyAssignColumns = useCallback(
    (headers: string[]) => {
      const matchedColumns = {} as Record<CsvColumn, string>;
      headers.forEach(header => {
        for (const [column, name] of Object.entries<string>(columnNameMap)) {
          if (name.toLowerCase() === header.toLowerCase()) {
            matchedColumns[column as CsvColumn] = header;
          }
        }
      });
      if (!isEmpty(matchedColumns)) {
        onChange({ ...columnsMap, ...matchedColumns });
      }
    },
    [columnNameMap, columnsMap, onChange]
  );

  const handleCompleteParsing = useCallback(
    (results: csv.ParseResult<Record<string, string>>) => {
      const headers = results.meta.fields || [];
      setHeaders(headers);
      automaticallyAssignColumns(headers);

      if (!results.data.length) {
        setError(true);
      } else {
        setValues(results.data.slice(0, 6));
      }
    },
    [automaticallyAssignColumns]
  );

  const parseFile = useCallback(
    (f: File) => {
      csv.parse(f, {
        header: true,
        skipEmptyLines: true,
        complete: handleCompleteParsing,
        error: handleParsingError,
      });
    },
    [handleCompleteParsing]
  );

  useEffect(() => {
    if (hasParsedFile) {
      return;
    } else if (file) {
      parseFile(file);
      setHasParsedFile(true);
    } else {
      setValues([]);
      setHeaders([]);
    }
  }, [file, hasParsedFile, parseFile]);

  const setColumnValue = (column: CsvColumn, value: string) => {
    onChange({ ...columnsMap, [column]: value });
  };

  const renderColumnSelect = (h: string) => {
    const column = h as CsvColumn;
    return (
      <td width={CELL_WIDTH} key={h} className="headers-panel--select-cell">
        <Select<string>
          disabled={error}
          filterable={false}
          items={headers}
          popoverContentProps={{ className: "headers-panel--select" }}
          popoverProps={{ minimal: true }}
          onItemSelect={value => setColumnValue(column, value)}
          itemRenderer={(item, { handleClick }) => (
            <SelectItem slug={item} key={item} label={item} onClick={handleClick} active={columnsMap[column] === item} />
          )}
        >
          <Button
            fill
            alignText="left"
            text={columnsMap[column] || "Select column"}
            rightIcon="double-caret-vertical"
            e2eIdentifiers={[columnsMap[column] || "Select column"]}
          />
        </Select>
      </td>
    );
  };

  const renderColumnHeader = (header: CsvColumn) => <th key={header}>{columnNameMap[header]}</th>;

  const renderRow = (value: Record<string, string>) => {
    if (Object.values(columnsMap).every(v => !v)) {
      return null;
    }

    const key = Object.values(value).join("-");
    return (
      <tr key={key}>
        {availableHeaders.map((header: CsvColumn) => {
          const valueKey = columnsMap[header];

          return (
            <td width={CELL_WIDTH} key={header}>
              {value[valueKey] || ""}
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <div className="headers-panel">
      <Text className="headers-panel--title">Select columns to map</Text>
      {error && <p className="headers-panel--error">Selected file does not have enough data, please pick another one.</p>}
      <div className="headers-panel--content">
        <HTMLTable className="headers-panel--table">
          <thead>
            <tr>{availableHeaders.map(renderColumnHeader)}</tr>
            <tr>{availableHeaders.map(renderColumnSelect)}</tr>
          </thead>
          <tbody>{values.map(renderRow)}</tbody>
        </HTMLTable>
      </div>
    </div>
  );
};

export default observer(HeadersPanel);
