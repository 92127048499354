import { useCallback } from "react";
import { NonIdealState } from "@blueprintjs/core";
import { observer } from "mobx-react";

import CommentThreadList from "@components/Modeling/ModelingFrame/ModelBlock/Comments/CommentThreadList";
import { CommentLocationType } from "@components/Modeling/ModelingFrame/ModelBlock/Comments/utils";
import UserInfo from "@components/UserInfo/UserInfo";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { CommentDisplayParentType } from "@store/CommentStore";
import { StoreType } from "@store/types";

import CommentEditor from "./CommentEditor";
import { CommentThreadDisplayStyle } from "./CommentThread";

import "./CommentsPanel.scss";

type CommentsPanelProps = {
  block: IBlock | undefined;
};

function CommentsPanel({ block }: CommentsPanelProps) {
  const handleNewComment = useCallback(
    (value: string, attachmentIds: string[], publishAttachmentsToBlock: boolean) => {
      if (!block) return;

      block.commentThreadList.addThreadAndParentComment({
        workspaceId: appStore.workspaceModel?.id,
        parentType: StoreType.Block,
        parentId: block.id,
        displayParentType: CommentDisplayParentType.Block,
        displayParentId: block.id,
        text: value,
        attachmentIdList: attachmentIds,
        publishToBlock: publishAttachmentsToBlock,
      });
    },
    [block]
  );

  if (!block?.commentThreadList?.threads || !appStore.workspaceModel?.id) {
    return null;
  }

  return (
    <div className="comments-panel">
      <CommentThreadList
        commentThreadList={block.commentThreadList}
        showOlderThreads
        type={CommentLocationType.Root}
        parentId={block.id}
        displayStyle={CommentThreadDisplayStyle.Panel}
      />
      {!block.commentThreadList.threads.length && (
        <NonIdealState iconSize={20} icon="chat" title="No comments yet" description="Be the first to start the discussion" />
      )}
      <div className="comments-panel--new-comment">
        {appStore.userModel && <UserInfo user={appStore.userModel} size="small" avatarOnly />}
        <CommentEditor
          workspaceId={appStore.workspaceModel?.id}
          className="comments-panel--comment-editor"
          onConfirm={handleNewComment}
          content=""
          resetOnConfirm
          placeholder="Add comment..."
          draggedImageLink={block.ui.discussionDraggedImageLink}
          onClearDraggedImageLink={block.ui.clearDiscussionDraggedImageLink}
          isExpanded
          autoEditOnFocus
        />
      </div>
    </div>
  );
}

export type { CommentsPanelProps };
export default observer(CommentsPanel);
