export enum RequirementBlockLevel {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
  Seven = 7,
  Zero = 0,
}

// needs to filter out strings (e.g. "One", "Two", "Three") from the enum
export const requirementBlockLevelOptions = Object.values(RequirementBlockLevel).filter(
  value => typeof value === "number"
) as RequirementBlockLevel[];
